import logo from './logo.jpg'
import './App.css'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Son del Sur</h1>
        <a href="https://linktr.ee/sondelsur">
          <img src={logo} alt="logo" className='App-logo' />
        </a>
      </header>
    </div>
  )
}

export default App
